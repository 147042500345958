import React from 'react'
import ToolHeading from './ToolHeading'
import ToolsSlider from './toolsSlider'

const ToolsSingle = ({Background, posts}) =>{
  return (
    <div className={`py-120 text-center ${Background}`}>
      <div className="container">
        <div className="row justify-content-center text-center mb-md-5 mb-4 pb-md-3">
          <div className="col-sm-11 col-md-9 col-lg-7 col-xxl-8">
              <ToolHeading />
          </div>
        </div>
      </div>
      {/* row gx-lg-5 row gx-lg-5  */}
      <div className="tools justify-content-center">
        <ToolsSlider posts={posts} />
      {/* {posts && posts.slice(0, 4).map((item, index) => (
        item.featuredImage && 
          <div className="col-5 col-sm-3 col-md-3 mt-3" data-aos="zoom-in" data-aos-delay={(index + 1) * 100}>
            <Link to={'/tool/' + item.slug} className="swiper-slide">
              <div className="tool-circle">
                <img loading="lazy" src={item.featuredImage && item.featuredImage.node.mediaItemUrl} alt={item.featuredImage && item.featuredImage.node.altText} className="gatsby-image-wrapper" />
              </div>
            </Link>
          </div>
        ))}  */}
      </div>      
    </div>
  )
}

export default ToolsSingle;