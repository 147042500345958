import React from 'react'

const TechnologiesUsed = ({tech, title, content}) =>{
  return (
    <div className={`py-120 pb-5 text-center`}>
      <div className="container">
        <div className="row justify-content-center text-center mb-md-5 mb-4 pb-md-3">
          <div className="col-sm-11 col-md-10 col-lg-8 col-xxl-10">
              <h2 className='h1' data-aos="fade-up" data-aos-delay="200" dangerouslySetInnerHTML={{__html: title}} />
              <p  data-aos="fade-up" data-aos-delay="400" dangerouslySetInnerHTML={{__html: content}} />
          </div>
        </div>

        <ul className='list-unstyled tech-trangle flex-wrap d-flex align-items-start'>
          {tech && tech.map((label, index) => (
            <li key={index} data-aos="zoom-in" data-aos-delay={(index + 1) * 100}><span dangerouslySetInnerHTML={{__html: label.organizationName}} /></li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default TechnologiesUsed;