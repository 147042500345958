import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ToolsSingle from "../components/toolsSingle"
import PostList from "../components/postList"
import TechnologiesUsed from "../components/technoloiesUsed"
import RatingStar from "../components/ratingStar"
import RatingDollar from "../components/ratingDollar"

const ToolDetails = ({data}) => {
    const page = data.wpTool;
    // console.log(page);
  
    return (
    <Layout>
        <Seo title={page.seo.title} image={page.featuredImage && page.featuredImage.node.mediaItemUrl} description={page.seo.metaDesc} focuskw={page.seo.focuskw} />
        
        <div className="page-intro page-inner">
            <div className="container">
                <div className="row mt-xl-0 mt-4">
                    {/* col-8 col-sm-5 col-md-5 col-lg-4 col-xxl-4 */}
                    <div className="col-12">
                        <div data-aos="zoom-up">
                            <img loading="lazy" width="160" height="80" src={page.featuredImage && page.featuredImage.node.mediaItemUrl} alt={page.featuredImage && page.featuredImage.node.altText} className="mb-4 tool-icon gatsby-image-wrapper" />
                        </div>
                        <div data-aos="fade-up" className="subtitle pt-xl-3 text-uppercase h5">TOOLS</div>
                        <h1 data-aos="fade-up" className="title position-relative mb-0" dangerouslySetInnerHTML={{__html: page.title}} />
                    </div>
                    <div className="w-100 mb-lg-4 mb-2 pb-3"></div>
                    <div className="col-12 col-sm-10 col-md-8 col-lg-7 col-xxl-8 lead-lg" data-aos="fade-up"  dangerouslySetInnerHTML={{__html: page.content}} />
                </div>

                <div className="mt-md-5 mt-4 text-uppercase pt-md-3">
                    <div data-aos="fade-up" className="mb-lg-4 mb-md-3 mb-2 bg-grad rating-stip d-flex align-items-center justify-content-between">
                        <p className="m-0">Security</p>
                        <RatingStar value={page.tools && page.tools.security} />
                    </div>
                    <div data-aos="fade-up" className="mb-lg-4 mb-md-3 mb-2 bg-grad rating-stip d-flex align-items-center justify-content-between">
                        <p className="m-0">SPEED</p>
                        <RatingStar value={page.tools && page.tools.speed} />
                    </div>
                    <div data-aos="fade-up" className="mb-lg-4 mb-md-3 mb-2 bg-grad rating-stip d-flex align-items-center justify-content-between">
                        <p className="m-0">SCALABILITY</p>
                        <RatingStar value={page.tools && page.tools.scalability} />
                    </div>
                    <div data-aos="fade-up" className="mb-lg-4 mb-md-3 mb-2 bg-grad rating-stip d-flex align-items-center justify-content-between">
                        <p className="m-0">DEVELOPMENT EFFORT</p>
                        <div className="rating-text d-flex align-items-center">
                            <span className={ page.tools.developmentEffort && page.tools.developmentEffort[0] === "Easy" && "active" }>Easy</span>
                            <span className={ page.tools.developmentEffort && page.tools.developmentEffort[0] === "Medium" && "active" }>MEDIUM</span>
                            <span className={ page.tools.developmentEffort && page.tools.developmentEffort[0] === "Hard" && "active" }>HARD</span>
                        </div>
                    </div>
                    <div data-aos="fade-up" className="mb-lg-4 mb-md-3 mb-2 bg-grad rating-stip d-flex align-items-center justify-content-between">
                        <p className="m-0">MAINTAINANCE</p>
                        <div className="rating-text d-flex align-items-center">
                            <span className={ page.tools.maintenance && page.tools.maintenance[0] === "Easy" && "active" }>Easy</span>
                            <span className={ page.tools.maintenance && page.tools.maintenance[0] === "Medium" && "active" }>MEDIUM</span>
                            <span className={ page.tools.maintenance && page.tools.maintenance[0] === "Hard" && "active" }>HARD</span>
                        </div>
                    </div>
                    <div data-aos="fade-up" className="mb-lg-4 mb-md-3 mb-2 bg-grad rating-stip d-flex align-items-center justify-content-between">
                        <p className="m-0">SEO</p>
                        <RatingStar value={page.tools && page.tools.seo} />
                    </div>
                    <div data-aos="fade-up" className="mb-lg-4 mb-md-3 mb-2 bg-grad rating-stip d-flex align-items-center justify-content-between">
                        <p className="m-0">COST OF DEVELOPMENT</p>
                        <RatingDollar value={page.tools && page.tools.costOfDevelopment} />
                    </div>
                </div>
            </div>
        </div>

        <TechnologiesUsed tech={page.tools.whoIsUsingOrganizationsName} title={page.tools.whoIsUsingTitle} content={page.tools.whoIsUsingSubTitle} />

        <ToolsSingle posts={page.tools.similarTools} />

        {page.tools.discoverMoreAboutBlogs?.length > 0 && (
          <div className="py-120 mb-5 pt-5">
              <div className="container">
                  <div className="row align-items-center justify-content-center justify-content-xl-between">
                      <div className="d-flex col-sm-10 col-xl-4 m-auto">
                          <div className="m-xl-auto w-100 mb-xl-0 mb-md-5 mb-4 d-flex flex-xl-wrap justify-content-xl-start justify-content-between">
                            {/*  data-aos="fade-up" data-aos-delay="0.2s" */}
                          <h2 className="mb-xl-3 h1 w-100 mb-0">Discover <em>more <br />about</em>  {page.title}</h2>
                          <Link aria-label="View All blogs" to="/blog" className="btn text-uppercase btn-link d-inline-flex align-items-center">
                              ViEW ALL

                              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                              <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                              </svg>
                          </Link>
                          </div>
                      </div>
                      <div className="col-xl-6 col-sm-10">
                          <PostList posts={page.tools.discoverMoreAboutBlogs} />
                      </div>
                  </div>
              </div>
          </div>
        )}
        
    
    </Layout>
 );
}

export default ToolDetails

export const query = graphql `
  query($slug: String) {
    wpTool(slug: { eq: $slug }) {
      seo {
        title
        metaDesc
        focuskw
      }
        title
        content
        featuredImage {
          node {
            altText
            mediaItemUrl
          }
        }
        tools {
          costOfDevelopment
          scalability
          security
          seo
          speed
          whoIsUsingSubTitle
          whoIsUsingTitle
          developmentEffort
          maintenance
          similarTools {
            ... on WpTool {
              slug
              featuredImage {
                node {
                  altText
                  mediaItemUrl
                }
              }
            }
          }
          discoverMoreAboutBlogs {
            ... on WpBlog {
              slug
              title
              featuredImage {
                node {
                  sourceUrl
                  altText
                  mediaDetails {
                    sizes {
                      sourceUrl
                    }
                  }
                }
              }
            }
          }
          whoIsUsingOrganizationsName {
            organizationName
          }
        }
    }
  }
`